import React from 'react';

const validChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const invalidChars = /["'();,=!<>[\]]/;
  if (invalidChars.test(e.key)) {
    e.preventDefault();
  }
};

export default validChar;
