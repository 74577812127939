import {
  ApplicationProvider,
  BreadCrumbProvider,
  FirebaseNotifications,
  Footer,
  KeycloakService,
  NotificationContainer,
  SidebarToggle,
  VersionModal
} from '@elotech/components';
import axios from 'axios';
import classnames from 'classnames';
import AppKeycloakProvider from 'common/providers/AppKeycloakProvider';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ErrorBoundary } from '../src/pages/error/ErrorBoundary';
import keycloakConfig from './keycloak';
import { AppHeader, AppSidebar } from './menus';
import Routes, { allRoutes } from './routes/Routes';
import { UsuarioService } from './service';
import createStore from './state';

if (!global.Intl) {
  global.Intl = require('intl');
}

const URL_KEYCLOAK = window.location.host.includes('.qa.')
  ? 'https://openid.qa.elotech.com.br/auth'
  : window.location.host.includes('.dev.')
  ? 'https://openid.dev.elotech.com.br/auth'
  : window.location.host.includes('.oxy.')
  ? 'https://openid.oxy.elotech.com.br/auth'
  : 'http://localhost:8080/auth';

const store = createStore({});

const keycloakConfigWithUrl = { ...keycloakConfig, url: URL_KEYCLOAK };

KeycloakService.init(keycloakConfigWithUrl, true);

axios.interceptors.request.use(async config => {
  const { ...newConfig } = config;

  if (
    newConfig.url.startsWith('/auth') ||
    newConfig.url.startsWith('/gateway') ||
    newConfig.url.startsWith('/console-api') ||
    newConfig.url.startsWith(KeycloakService.getInstance()?.authServerUrl)
  ) {
    const refreshed = await KeycloakService.refreshToken({});
    if (refreshed) {
      newConfig.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
    }
  }
  return Promise.resolve(newConfig);
});

const notificationService = new FirebaseNotifications('notificationsConsole');

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={{}}>
          <IntlProvider locale="pt">
            <NotificationContainer>
              <AppKeycloakProvider>
                <ApplicationProvider
                  notificationService={notificationService}
                  getUserPermission={UsuarioService.getRoles}
                >
                  <BreadCrumbProvider routes={allRoutes}>
                    <SidebarToggle>
                      {({ toggledSidebar, userExists, user }) => {
                        return (
                          <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <div className="wrapper">
                              <AppHeader showMenu={true} currentUser={user} />
                              <AppSidebar showSideBar={true} />
                              <section
                                className={classnames({
                                  'main-content': true,
                                  'sidebar-toggle': toggledSidebar,
                                  'no-sidebar': false,
                                  'sidebar-toggle-md': true
                                })}
                              >
                                <VersionModal
                                  actuatorBaseUrl={'/console-api'}
                                />
                                <ErrorBoundary>
                                  <Routes
                                    hasUsuarioRole={true}
                                    userExists={userExists}
                                    currentUser={user}
                                  />
                                </ErrorBoundary>
                              </section>
                              <Footer />
                            </div>
                          </BrowserRouter>
                        );
                      }}
                    </SidebarToggle>
                  </BreadCrumbProvider>
                </ApplicationProvider>
              </AppKeycloakProvider>
            </NotificationContainer>
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
